$mobile-width: 480px;
$old-mobile-width: 400px;
$desktop-width: 1440px;
$laptop-width: 992px;
$form-width: 767px;
$dark-blue-color: #233464;
$blue-unique: #173f5f;
$blue: #0966f1;
$blue-unique2: #20639b;
$light-blue: #d5f3fe;
$blue2: #3c99dc;
$blue3: #66d3fa;
$light-gray: #cfd7e4;
$yellow: #f6d55c;
$red: #ed553b;
$red-p: #ff0d00;
$green: #3caea3;
$carrot: #ed9121;
$orange: #fbdeb3;
$orange-middle: #ffbe46;
$dark-orange: #efb261;
$white: #ffffff;
$black: #000000;

// Mixin
@mixin mobile() {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (max-width: $desktop-width) {
    @content;
  }
}

// End mixin

// @font-face {
//   font-family: "Lato-regular";
//   src: url("./assets/fonts/Lato-Regular.ttf");
//   font-display: swap;
// }
.base-font-class {
  margin-bottom: 2em;
  letter-spacing: 0.12em;
  word-spacing: 0.16em;
  line-height: 1.5em;
}

.normal-font {
  @extend .base-font-class;
  font-family: "Lato-regular", "sans-serif";
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: "Segoe UI", sans-serif;
  box-sizing: border-box;
  min-height: 100%;
  display: grid;
  margin: 0;

  //   font-weight: 400;
  //   line-height: 1.5;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr min-content;
  grid-template-columns: 100%;
}

.paragraph,
.span {
  max-width: 80ch;
}

.container {
  max-width: 1280px;
  width: 90%;
  margin: 0 auto;

  &__sub {
    max-width: $laptop-width;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", "sans-serif";
  color: $dark-blue-color;
}

.page-header {
  font-size: 2.5rem;

  @include mobile() {
    font-size: calc(1rem + 2vw);
  }
}

.large-header {
  // font-size: 2rem;
  font-size: clamp(1.5rem, calc(1rem + 2vw), 2rem);
  margin: 0.75rem 0 2.5rem;

  @include mobile() {
    // font-size: calc(1rem + 2vw);
    margin-bottom: 1.5rem;
  }
}

.medium-header {
  font-size: 1.5rem;
}

.normal-header {
  font-size: 1.25rem;
}

.small-header {
  font-size: 1.125rem;
}

.tiny-header {
  font-size: 1.1rem;
}

.lead {
  font-size: 1.125rem;
}

header {

  // margin-bottom: 2rem;
  .wrapper {
    background-color: $blue-unique;
    padding: 3rem 0;

    img {
      max-width: 200px;
    }
  }

  .profile {
    &__wrapper {
      float: right;
      position: relative;
    }

    &__dropdown {
      // background-color: lighten($dark-blue-color, 70%);
      background: none;
      background-image: url("../src/assets/images/person1.svg");
      background-size: 2.5rem;
      background-position: 5px 50%;
      background-repeat: no-repeat;
      min-width: 85px;
      border: none;
      cursor: pointer;
      padding: 1.75rem 1.25rem;

      &__background {
        background-color: lighten($dark-blue-color, 70%);
      }

      &:hover,
      &:active,
      &:focus {
        background-color: lighten($dark-blue-color, 70%);
      }

      &:after {
        content: " ";
        background-image: url("../src/assets/images/arrow-down-blue2.png");
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        padding: 0.75rem;
        position: absolute;
        bottom: 1rem;
      }
    }

    &__menu {
      position: absolute;
      right: 1px;
      margin-top: 1px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 1px 1px #ccc;
      background-color: #fcfcfc;
      min-width: 200px;
      padding: 1rem 0;
      user-select: none;

      &__no-display {
        display: none;
      }

      span,
      button,
      >* {
        display: block;
        padding: 0.5rem 1rem;
        margin-bottom: 0.25rem;
        text-align: left;
        font-size: 1.05rem;
        font-family: "lato";
      }

      button {
        border: none;
        background: none;
        text-decoration: underline;
        color: $dark-blue-color;
        cursor: pointer;

        &:hover,
        :focus,
        &:active {
          background-color: lighten($light-gray, 5%);
        }
      }
    }
  }
}

footer {
  padding: 2.5rem 0;
  margin-top: 3rem;
  background-color: $blue-unique;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: #fff;
  }

  a {
    color: lighten($blue, 37%);
  }
}

.contact {
  @extend .form-container;
  text-align: center;

  p {
    // text-align: justify;
    // text-align-last: center;
    margin: auto;
    max-width: 500px;
  }

  a {
    text-decoration: underline;
  }
}

.smslogin {
  form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;

    input[type="text"],
    input[type="email"] {
      padding: 0.7rem;
      margin-right: 0.5rem;
      margin-bottom: 0.75rem;
      min-width: 210px;
      font-size: 1rem;
      border-width: thin;
      border-radius: 5px;
      background-color: $white;
    }

    button {
      margin-bottom: 0.75rem;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      color: $white;
      background-color: $dark-blue-color;
      font-size: 1rem;

      &:disabled {
        background-color: lighten(gray, 30%);
        color: gray;
      }

      &:hover,
      &:focus {
        color: $orange-middle;
        cursor: pointer;
      }
    }
  }
}

.submit-button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: $white;
  background-color: $dark-blue-color;
  font-size: 1rem;

  &:disabled {
    background-color: lighten(gray, 30%);
    color: gray;
  }

  &:hover,
  &:focus {
    color: $orange-middle;
    cursor: pointer;
  }
}
.resetButton{
  border: none;
  padding: 0.5rem ;
  border-radius: 5px;
  color: $white;
  background-color: darken($green, 20%);
  cursor: pointer;
  &:hover{
    color:lighten($yellow,8%);
    background-color: darken($green, 25%);
  }
}

.form-container {
  width: 90%;
  max-width: $form-width;
  margin: auto;
}

.main-navigation {
  background-color: $dark-blue-color;
  padding: 2.25rem 0;
  margin-bottom: 3rem;

  ul {
    @extend .form-container;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    justify-self: center;
    list-style: none;
    counter-reset: item;
    flex-wrap: wrap;

    li {
      // text-transform: capitalize;
      position: relative;
      font-family: "Lato", sans-serif;

      @include mobile() {
        flex-basis: 50%;
      }

      a {
        color: #fff;
        display: block;
        font-size: 1.05rem;
        text-align: center;
        margin: 0.75rem;

        &::before {
          counter-increment: item;
          content: counter(item);
          display: flex;
          margin: auto;
          margin-bottom: 0.5rem;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          line-height: 2rem;
          width: 2rem;
          height: 2rem;
          border: 2px solid lighten(#fff, 15%);
          color: lighten(#fff, 15%);
          text-decoration: none;
        }

        &.ready {
          &::before {
            border-style: dotted;
          }
        }

        &.active {
          cursor: pointer;

          &::before {
            background-color: #fff;
            color: darken($dark-blue-color, 5%);
            border: 2px solid #fff;
            border-style: solid;
          }
        }

        &.current-active {
          cursor: context-menu;

          &::before {
            font-weight: bold;
          }
        }
      }
    }
  }
}

//Form

.radio-label {
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  margin-bottom: 0.3rem;
  // cursor: pointer;
  font-size: 1.05rem;
  user-select: none;
  border: 1px solid transparent;

  input {
    position: absolute;
    opacity: 0;
    // cursor: pointer;

    &:focus {
      +.radio-symbol {
        background-color: lighten(#cfd7e4, 7%);
        // border-color: $dark-blue-color;
      }
    }
  }

  &:hover {
    background-color: lighten(#cfd7e4, 7%);
    // border-color: $dark-blue-color;
  }

  .radio-symbol {
    position: relative;
    display: flex;
    padding: 0.5rem;
    padding-left: 2.5rem;
    align-items: flex-start;

    &:before {
      position: absolute;
      content: "";
      height: 1rem;
      width: 1rem;
      background-color: lighten(#cfd7e4, 12%);
      border: 1px solid darken($dark-blue-color, 15%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      top: 10px;
      justify-content: center;
      left: 0.5rem;
    }
  }

  input:checked {
    ~.radio-symbol {
      &:before {
        height: .9rem;
        width: .9rem;
        background-color: $blue-unique2;
        border-color: #fff;
        box-shadow: 0 0 1px 1px $dark-blue-color;
        border-width: 2px;
      }
    }
  }
}

.checkbox-label {
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  margin: 0.75rem 0;
  // cursor: pointer;
  font-size: 1.05rem;
  user-select: none;
  border: 1px solid transparent;

  input {
    position: absolute;
    opacity: 0;
    // cursor: pointer;

    &:focus {
      +.checkbox-symbol {
        background-color: lighten(#cfd7e4, 7%);
        // border-color: $dark-blue-color;
      }
    }
  }

  &:hover {
    background-color: lighten(#cfd7e4, 7%);
    // border-color: $dark-blue-color;
  }

  .checkbox-symbol {
    position: relative;
    display: flex;
    padding: 0.5rem;
    padding-left: 2.75rem;
    align-items: flex-start;

    &:before {
      position: absolute;
      content: "";
      height: 1.1rem;
      width: 1.1rem;
      background-color: lighten(#cfd7e4, 12%);
      border: 2px solid lighten($dark-blue-color, 8%);
      border-radius: 2px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      left: 0.5rem;
    }
  }

  input:checked {
    ~.checkbox-symbol {
      &:before {
        background-color: lighten($dark-blue-color, 10%);
      }

      &:after {
        content: "";
        position: absolute;
        display: flex;
        left: 15px;
        top: 9px;
        width: 5px;
        border-radius: 1px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}

input[type="date"] {

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0;
  }

  appearance: none;
  background-image: url("../src/assets/images/calendar.png");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  min-height: 51.75px;
}

// .select-block {
//   margin: 1.5rem auto;
//   label {
//     font-size: 1.1rem;
//     display: block;
//     margin-bottom: 0.8rem;
//   }
//   select {
//     padding: 1rem;
//     padding-left: 0.8rem;
//     width: 100%;
//     font-size: 1.1rem;
//     border-radius: 5px;
//     border-color: $dark-blue-color;
//     background: url(../src/assets/images/rsz_arrow-down2.png) no-repeat
//       lighten(#cfd7e4, 10%);
//     appearance: none;
//     background-position: calc(100% - 1rem) 50%;

//     option {
//       background-color: lighten(#cfd7e4, 14%);
//       font-family: "Lato", sans-serif;
//       font-size: 1rem;
//     }
//   }
// }

.form-navigation {
  @extend .form-container;
  margin: 3rem 0;
  display: flex;
  flex-flow: row wrap;

  a {
    text-decoration: none;
  }
}

.navigation-button {
  border: none;
  background: none;
  border-radius: 3px;
  padding: 0.8rem 1.5rem;
  background-color: darken($blue, 15%);
  color: #fff;
  font-size: 1.05rem;
  margin: 0 1rem 1rem 0;
  cursor: pointer;
  text-decoration: unset;

  &:hover,
  &:active,
  &:focus {
    background-color: $dark-blue-color;
    color: darken($orange, 10%);
  }
}

.login-button {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  width: 250px;
  border-radius: 5px;
  padding: 1.25rem 1.5rem;
  background-color: $blue;
  color: #fff;
  font-size: 1.25rem;
  background-image: url("../src/assets/images/lock.svg");
  background-repeat: no-repeat;
  background-size: 3rem;
  background-position: left center;
  margin: 3.5rem auto 2rem;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: darken($blue, 12%);
    color: white;
  }

  @include mobile() {
    width: 180px;
    padding: 1rem;
    font-size: 1.15rem;
    background-size: 2.5rem;
  }
}

.input-label {
  margin: 2.1rem 0;
  display: block;

  span {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.02rem;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"],
  textarea,
  select {
    width: 100%;
    box-sizing: border-box;
    font-size: 1.05rem;
    background-color: lighten(#cfd7e4, 14%);
    padding: 0.8rem;
    border: 1px solid darken(#cfd7e4, 25%);
    border-radius: 3px;
    min-height: 51.75px;

    &:focus,
    &:hover {
      background-color: lighten(#cfd7e4, 12%);
    }

    &:disabled {
      background-color: lighten($light-gray, 10%);
    }
  }

  textarea {
    min-height: 6rem;
  }
}

select {
  appearance: none;
  background-image: url("../src/assets/images/arrow-down.png");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  min-height: 51.75px;
}

//File
.file-label {
  // display: flex;

  &__symbol {
    font-size: 1.15rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed $dark-blue-color;
    padding: 2rem 5rem;
    border-radius: 5px;
    background-color: lighten(#cfd7e4, 10%);
    background-image: url("../src/assets/images/upload.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem);
    background-size: 1.25rem;
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 0;

    &:focus,
    &:active,
    &:hover {
      +span {
        background-color: #cfd7e4;
      }
    }
  }
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 1.5rem auto;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0.75rem;
    background-color: lighten(#cfd7e4, 5%);
    margin-bottom: 3px;
    border-radius: 5px;
    font-size: 1.11rem;
    color: darken($dark-blue-color, 10%);
    width: 100%;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    color: #8b0000;

    span {
      display: block;
      font-size: 2rem;
    }
  }
}

.error-message {
  width: 100%;
  color: lighten(#8b0000, 3%);
  padding: 0.8rem;
  margin-top: 1rem;
  font-size: 1.05rem;
  background-color: lighten(#8b0000, 69%);
}

.no-display {
  display: none;
}

.no-pointer-events {
  cursor: not-allowed;
}

.receiptInfo {
  margin-bottom: 3rem;
}

.buttonDisabled {
  background-color: #cfd7e4;
  color: darken(#353535, 10%);

  &:hover {
    background-color: #cfd7e4;
    color: darken(#353535, 10%);
    cursor: context-menu;
  }
}

.form-error {
  width: 100%;
  padding: 1.5rem 1rem;
  background-color: #f0f092;
  color: #000000;
  margin-top: 2rem;
}

@media (max-width: 700px) {
  .ChatFooter {
    display: block;
    padding: 15px;
    border: 1px solid grey;
    width: 30%;
    margin: 0;
    text-align: center;
    position: relative;
    border-bottom: 0;
    margin-top: 2%;
    padding-bottom: 5%;
    border-right: 0;
    right: -70%;
    border-top-left-radius: 25px;
  }

  // #root {
  //   display: none !important;
  // }

  .SuccessDiv {
    position: fixed;
    top: 30%;
    left: 5%;
    right: 5%;
    bottom: auto;
    margin: auto;
    z-index: 999;
    width: 90%;
    text-align: center;
    background-color: white;
    border: solid black 1px;
    padding: 15px;
  }
}

.semiBold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-color: lighten(#cfd7e4, 5%);
    border-right-color: #173f5f;
    animation: spin 1.5s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}

.access-denied {
  padding: 1rem;
}

.response-wrapper {
  margin-top: 5rem;

  img {
    display: block;
    max-width: 250px;
    margin: 0 0 2rem;
  }
}

.required {
  &:after {
    content: "*";
    margin-left: 0.75rem;
    color: #e91527f1;
    font-weight: bold;
  }
}

.alert-message {
  @extend .error-message;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 3rem auto;
  max-width: 960px;
}

.question-header {
  display: block;
  margin: 1.25rem 0;
  font-size: 1rem; //font-size: 1.075rem;
  font-weight: 550;
  color: $blue-unique;

  &__initial {
    display: initial;
  }

  &__extra-space {
    margin: 1.75rem 0;
  }
}

.display-block {
  display: block;
}

.mobile-display-block {
  @include mobile() {
    display: block;
  }
}

.align-center {
  text-align: center;
}

.home-container {
  max-width: $form-width;
  width: 90%;
  background-color: lighten($light-gray, 14%);
  box-shadow: 0 0 2px 1px $light-gray;
  padding: 2rem;
  margin: 2rem auto;

  @include mobile() {
    width: 100%;
  }
}

.white {
  color: #fff;
}

.welcome-container {
  display: flex;
  justify-content: space-between;
}

// .table-wrapper {
//   overflow: auto;
//   max-width: 100%;
//   white-space: nowrap;
//   margin-bottom: 2rem;
// }
.table {
  display: table;
  border-collapse: collapse;
  width: 90%;

  @include mobile() {
    width: 100%;
  }

  &__row {
    display: table-row;

    &__header {
      font-size: 1.1rem;
      background-color: $dark-blue-color;
      color: #fff;
    }

    background-color: #fcfcfc;

    &:nth-child(even) {
      background-color: lighten(lightsteelblue, 15%);
    }
  }

  &__cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0.5rem;
    text-overflow: ellipsis;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;

    // font-size: 1.05rem;
    @include mobile() {
      max-width: 100px;
    }

    @media screen and (max-width: 400px) {
      max-width: 80px;
    }
  }

  .title {
    padding: 0.75rem 0.5rem;

    @include mobile() {
      padding: 0.5rem 0.25rem;
    }
  }

  a {
    &:not(.table-link) {
      text-decoration: none;
      color: #000;

      &:active,
      &:hover,
      &:focus {
        background-color: lighten($blue3, 10%);
      }
    }

    transition: 0.1s;
    z-index: 9;
  }
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 500px;
    overflow: auto;

    span {
      text-align: center;
      padding: 1.25rem 1rem;
    }

    &__header {
      background-color: #204486;
      font-size: 1.1rem;
      color: #fff;
    }
  }

  &__row {
    background-color: #fcfcfc;

    &:nth-child(even) {
      background-color: lighten(lightsteelblue, 15%);
    }
  }

  a {
    text-decoration: none;
    color: #000;

    &:active,
    &:hover,
    &:focus {
      background-color: #f3f055;
    }
  }
}

// .action-cell {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
// }
.action-button {
  padding: 1rem;
  color: #fff;
  border: none;
  background-position: 50%;
  // background-size: 1.3rem;
  background-repeat: no-repeat;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  margin: 0.25rem;

  &__show {
    background-image: url("../src/assets/images/list_white.svg");
    background-color: darken($blue, 10%);

    &:hover,
    &:active,
    &:focus {
      background-color: darken($blue, 20%);
    }
  }

  &__edit {
    background-image: url("../src/assets/images/pensil_design_white.svg");
    background-color: #006147;

    &:hover,
    &:active,
    &:focus {
      background-color: darken(#006147, 5%);
    }
  }

  &__delete {
    background-image: url("../src/assets/images/bin_white.svg");
    background-color: #990303;

    &:hover,
    &:active,
    &:focus {
      background-color: darken(#990303, 5%);
    }
  }

  &__reply {
    background-image: url("../src/assets/images/arrow-right.svg");
    background-size: 1rem;
    background-color: darken($blue, 10%);

    &:hover,
    &:active,
    &:focus {
      background-color: darken($blue, 20%);
    }
  }
}

.attachment-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  margin: auto;
  font-size: 1.05rem;
  border-bottom: 1px dashed $dark-blue-color;

  &:first-of-type {
    border-top: 1px dashed $dark-blue-color;
  }

  a {
    background-color: darken($blue, 10%);
    display: inline-flex;
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    // background-image: url("./assets/images/download_orange.svg");
    // background-image: url("/assets/images/download.svg");
    // background-repeat: no-repeat;
    // background-position: 12px 50%;
    // background-size: 1.35rem;
    text-decoration: none;

    svg {
      margin-right: 0.8rem;
      max-height: 1.35rem;
      stroke: #fff;
    }

    @include mobile() {
      padding: 0.75rem 1.25rem;
      background-image: none;
      min-width: 110px;

      svg {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $dark-blue-color;

      svg {
        stroke: $orange-middle;
      }

      // background-image: url("./assets/images/download_orange.svg");
      color: $orange-middle;

      @include mobile() {
        // background-image: none;
      }
    }
  }

  @include mobile() {
    font-size: 1rem;
  }
}

.link-block {
  margin-top: 5rem;
  display: flex;

  a {
    color: darken($blue, 12%);
    font-weight: 550;
    font-size: 1.25rem;
    align-self: center;
    position: relative;
    padding: 0.5rem;
    padding-left: 2rem;

    &::before {
      // display: inline-flex;
      // align-self: center;
      // content: "\1F860";
      // font-size: 1.75rem;
      // text-decoration: none;
      // position: absolute;
      // left: 0;

      content: "";
      background-image: url("./assets/images/arrow-left-darkblue.svg");
      background-size: 1.75rem;
      width: 1.25rem;
      display: inline-flex;
      background-repeat: no-repeat;
      background-position: 0 50%;
      position: absolute;
      left: 2px;
      height: 1.25rem;
      top: 18px;
    }

    &:hover {
      color: darken($blue, 20%);
    }
  }
}

.message-block {
  margin-top: 1rem;
  padding: 1rem;
  background-color: lighten($blue, 48%);
}

// Utility
.margin {
  &__large {
    margin: 3rem 0;
  }
}

.margin-bottom {
  &__small {
    margin-bottom: 1rem;
  }

  &__large {
    margin-bottom: 3rem;
  }
}

.copyright {
  font-size: 0.85rem;
}

.loginbutton {
  border: 1px solid $blue-unique;
  background-color: lighten($light-blue, 7%);
  display: flex;
  padding: 1.5rem 1rem 1.5rem 4.5rem;
  margin: 1rem 0;
  border-radius: 3px;
  background-position: 10px 50%;
  background-size: 2.75rem;
  background-repeat: no-repeat;
  font-size: 1.1rem;
  cursor: pointer;
  color: $black;

  &:first-of-type {
    margin-top: 2rem;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $light-blue;
  }

  &__bankid {
    background-image: url("../src//assets/images/bankid-icon.png");
  }

  &__vipps {
    background-image: url("../src//assets/images/vipps-icon.png");
  }

  &__mitid {
    background-image: url("../src//assets/images/mitid-icon.png");
    background-size: 3.5rem;
  }

  &__sebankid {
    background-image: url("../src//assets/images/se_bankid.png");
    background-size: 4rem;
  }
}

.portal-title {
  margin-top: 3rem;
}

.padding-small {
  padding: 0.75rem;
}

.loggout {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-color: lighten(#cfd7e4, 7%);
    border-right-color: lighten(#233464, 25%);
    animation: spin 1.5s linear infinite;
  }
}

.spinner {
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;

  &__content {
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 1.5rem 1rem;
    min-width: 280px;
  }

  &__animation {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-color: lighten(#cfd7e4, 7%);
    border-right-color: $dark-blue-color;
    animation: spin 1.5s linear infinite;
  }
}

.contactFormWrapper {
  max-width: 765px;
  margin: auto;

  form {
    padding-top: 2.5rem;

    .information {
      font-size: 15px;
      padding-top: 1rem;
    }
  }
}

.portal-title {
  margin-top: 3rem;
}

.padding-small {
  padding: 0.75rem;
}

// Utility
.margin {
  &__large {
    margin: 3rem 0;
  }
}

.margin-bottom {
  &__small {
    margin-bottom: 1rem;
  }

  &__large {
    margin-bottom: 3rem;
  }
}

.margin-top {
  &__small {
    margin-top: 1rem;
  }
  &__medium {
    margin-top: 2rem;
  }
  &__large {
    margin-top: 3rem;
  }
}

.overflow-x-auto {
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
  width: 100%;

  @include mobile() {
    max-width: $mobile-width;
  }
}

.color-blue {
  color: lighten($dark-blue-color, 15%);
  font-size: 19px;
}

.table-link {
  color: lighten($blue-unique, 6%);
  font-size: 16px;
  text-decoration: underline;
  padding: 1rem 0;
  font-weight: 600;

  &:active,
  &:hover,
  &:focus {
    background-color: lighten($light-gray, 3%);
  }
}

.LyFullmaktForm {
  max-width: $laptop-width;
  margin: auto;
}

.ly-page-header {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.15rem;
  text-align: center;
  text-transform: uppercase
}

.ly-answer {
  padding: .25rem .5rem;
  background-color: lighten($blue3, 25%);
  max-width: fit-content;
}

.indentedItem {
  padding-left: 1rem;
}

.color-red {
  color: darken($red-p,25%);
}

.color-green {
  color: darken($green,25%);
}